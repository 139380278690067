/*
  Footer JS
*/
$(function () {
	OLEDUI.Footer = function () {

		// VARIABLE
		let windowInnerHeight = window.innerHeight;
		let currentScrollY = window.scrollY;
		let btnStoreTriggerWheelCount = 0;
		let btnStoreTriggerScrollHeight = 150;
		let wheelY = 0;

		// initialize
		controlFloatingStoreBtn();

		// toggle button
		const toggleBtn = $(".footer-nav button.toggle");
		toggleBtn.on("click", function (e) {
			e.preventDefault();

			const $this = $(this);
			const expand = $this.parent().hasClass("active");
			const alt = $this.data("alt");
			$this.parent().toggleClass("active");
			$this.attr("aria-expanded", !expand);
			if (expand) {
				//  closed
				$this.find(".sr-only").text(alt.expand);
			} else {
				// opened
				$this.find(".sr-only").text(alt.collapse);
			}
		});

		// language - mobile
		const $langAreaMobile = $(".footer.footer .lang.mobile-only");
		$langAreaMobile.on("click", function () {
			event.preventDefault();
			$langAreaMobile.toggleClass("is-active");
			var linkURL = $(event.target).attr("href");
			if (linkURL && linkURL.indexOf("#") == -1) {
				if (getCookie("test") != "!!") location.href = linkURL;
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "footer",
						navDepth1: "languages",
						navDepth2: convertTitle($(event.target).text()),
						clickURL: $(event.target).attr("href"),
					},
				});
			}
		});

		// language - desktop
		const $langAreaD = $(".footer.footer .lang.desktop-only");
		const $langArea = $(".footer.footer .lang.desktop-only > ul");
		const $langAreaDFirstLang = $("footer.footer .lang.desktop-only > ul li:first-child a");
		const $firstSNS = $("footer.footer .footer-social ul li:first-child a");
		$langAreaDFirstLang.on("focus", function () {
			if (!window.matchMedia("(max-width: 750px)").matches) {
				$langArea.addClass("expand");
			}
		});
		// $langAreaDFirstLang.on("mouseleave", function () {
		// 	$langArea.removeClass("expand");
		// });
		$langAreaD.on("mouseenter touchstart", function () {
			if (!window.matchMedia("(max-width: 750px)").matches) {
				$langArea.addClass("expand");
			}
		});
		$langAreaD.on("mouseleave", function () {
			$langArea.removeClass("expand");
		});
		$firstSNS.on("focus", function () {
			$langArea.removeClass("expand");
		});
		$langArea.find("li a").on("click", function (event) {
			event.preventDefault();
			if ($langArea.hasClass("expand")) {
				if (getCookie("test") != "!!") window.location.href = $(this).attr("href");
				pushDataLayer({
					event: "click_nav",
					click_nav: {
						navCategory: "footer",
						navDepth1: "languages",
						navDepth2: convertTitle($(this).text()),
						clickURL: $(this).attr("href"),
					},
				});
			} else {
				if (window.matchMedia("(max-width: 750px)").matches) {
					$langArea.addClass("expand");
				} else {
					return false;
				}
			}
		});

		// click links
		const $linkDepth1 = $(".footer.footer .depth1 > a");
		$linkDepth1.on("click", function () {
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "footer",
					navDepth1: convertTitle($(this).text()),
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});
		const $linkDepth2 = $(".footer.footer .depth2 a");
		$linkDepth2.on("click", function () {
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "footer",
					navDepth1: convertTitle($(this).closest(".depth1").find("> span").text()),
					navDepth2: convertTitle($(this).text()),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});

		// social
		const $linkSocial = $(".footer.footer .footer-social a");
		$linkSocial.on("click", function () {
			pushDataLayer({
				event: "click_social_media",
				click_social_media: {
					socialMediaName: convertTitle($(this).parent().attr("class")),
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});

		// etc links
		const $linkEtc = $(".footer.footer .footer-links a");
		$linkEtc.on("click", function () {
			pushDataLayer({
				event: "click_nav",
				click_nav: {
					navCategory: "footer",
					navDepth1: convertTitle($(this).text()),
					navDepth2: "",
					clickURL: $(this).attr("href") == "#" ? "" : $(this).attr("href"),
				},
			});
		});

		/* floating button */
		const floatingArea = OLEDUI.layout.footer.find(".floating");
		const topButton = floatingArea.find(".floating__btn--top");
		const newsletterBtn = floatingArea.find(".floating__btn--newsletter");
		const storeBtnWrap = document.querySelector('.footer .floating');
		const storeBtn = document.querySelector('.floating__btn--store');
		let lastScroll = 0;

		topButton.on("click", function (e) {
			e.preventDefault();
			if ($(".search-layer").hasClass("active")) {
				$(".search-layer").stop().animate({ scrollTop: 0 }, 500);
				$(".search-layer").find(".btn-layer-close").focus();
			} else {
				$("html, body").stop().animate({ scrollTop: 0 }, 500);
				if (OLEDUI.layout.header.length > 0) {
					OLEDUI.layout.header.find(".logo a").focus();
				}
			}
		});
		
		$(window).on("scroll.top", function () {
			const st = $(window).scrollTop();
			if (st === 0 && !$(".search-layer").hasClass("active")) {
				floatingArea.removeClass("top-show");
				if($('.PRODt').length > 0) floatingArea.removeClass("newsletter-show");
			}
			else if (lastScroll > st) {
				floatingArea.addClass("top-show");
				if($('.PRODt').length > 0) floatingArea.addClass("newsletter-show");
			}
			else if (lastScroll < st) {
				floatingArea.removeClass("top-show");
				if($('.PRODt').length > 0) floatingArea.removeClass("newsletter-show");
			}
			lastScroll = st;
		});

		function controlFloatingStoreBtn() {
			if (storeBtn === undefined || storeBtnWrap === undefined) return;
			if (currentScrollY > btnStoreTriggerScrollHeight) btnStoreTriggerWheelCount = 2; // pc에서 페이지 새로고침 고려

			if (storeBtn.classList.contains('is-touch') === true) { // 터치 디바이스인 경우
				if (currentScrollY === 0) {
					storeBtnWrap.classList.remove('store-show');
				}
				if (currentScrollY > btnStoreTriggerScrollHeight) {
					storeBtnWrap.classList.add('store-show');
				}
			} else {
				if (currentScrollY === 0) {
					storeBtnWrap.classList.remove('store-show');
					btnStoreTriggerWheelCount = 0;
				} else {
					if (btnStoreTriggerWheelCount > 1) {
						storeBtnWrap.classList.add('store-show');
					}
				}
			}
		}
		if(storeBtn !== undefined) {
			storeBtn.addEventListener("click", function (event) {
				pushDataLayer({
					event: "click_button",
					click_button: {
						buttonText: convertTitle($(event.target).text()),
						clickURL: this.getAttribute("href"),
					},
				});
			});
		}

		newsletterBtn.on("click", function () {
			pushDataLayer({
				event: "download_brochure",
				download_brochure: { clickURL: $(this).attr("href") },
			});
		});
		/* // floating button */

		// wheel event
		window.addEventListener('wheel', function (e) {
			wheelY = e.deltaY || e.wheelDelta || e.wheelDeltaY;
			if (wheelY > 0) btnStoreTriggerWheelCount++
		})

		// scroll event
		window.addEventListener('scroll', function () {
			currentScrollY = window.scrollY;
			controlFloatingStoreBtn();
		})

		// resize event
		window.addEventListener('resize', function () {
			windowInnerHeight = window.innerHeight;
		})
	};
	OLEDUI.Footer();
});
